import React from 'react';
import {reactNormalize} from '../../utilities';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';
import Paragraph from './Paragraph';

import * as styles from './TableData.module.scss';

interface TableDataProps extends
  React.DetailedHTMLProps<React.TdHTMLAttributes<any>, any> {

}

const TableData = (props: TableDataProps) => {
  return <LayoutTraitsContext.Consumer>
    { (layoutTraits) =>
      <td
        className={layoutTraits.getWrapperStyle(styles)}
        align={props.align}
      >
        {
          reactNormalize(props.children).map((child, index, array) => {
            const traits = LayoutTraits.makeSubElement(index, array.length);
            return (
              <LayoutTraitsContext.Provider
                key={index}
                value={traits}>
                <Paragraph>
                  {child}
                </Paragraph>
              </LayoutTraitsContext.Provider>
            );
          })
        }
      </td>

    }
  </LayoutTraitsContext.Consumer>;
};

export default TableData;
