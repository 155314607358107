Prism.languages.swift = {

  'comment': {
    // Nested comments are supported up to 2 levels
    pattern: /(^|[^\\:])(?:\/\/.*|\/\*(?:[^/*]|\/(?!\*)|\*(?!\/)|\/\*(?:[^*]|\*(?!\/))*\*\/)*\*\/)/,
    lookbehind: true,
    greedy: true,
  },
  'string-literal': [
    // https://docs.swift.org/swift-book/LanguageGuide/StringsAndCharacters.html
    {
      pattern: RegExp(
        /(^|[^"#])/.source +
        '(?:' +
        // single-line string
        /"(?:\\(?:\((?:[^()]|\([^()]*\))*\)|\r\n|[^(])|[^\\\r\n"])*"/.source +
        '|' +
        // multi-line string
        /"""(?:\\(?:\((?:[^()]|\([^()]*\))*\)|[^(])|[^\\"]|"(?!""))*"""/.source +
        ')' +
        /(?!["#])/.source,
      ),
      lookbehind: true,
      greedy: true,
      inside: {
        'interpolation': {
          pattern: /(\\\()(?:[^()]|\([^()]*\))*(?=\))/,
          lookbehind: true,
          inside: null, // see below
        },
        'interpolation-punctuation': {
          pattern: /^\)|\\\($/,
          alias: 'punctuation',
        },
        'punctuation': /\\(?=[\r\n])/,
        'string': /[\s\S]+/,
      },
    },
    {
      pattern: RegExp(
        /(^|[^"#])(#+)/.source +
        '(?:' +
        // single-line string
        /"(?:\\(?:#+\((?:[^()]|\([^()]*\))*\)|\r\n|[^#])|[^\\\r\n])*?"/.source +
        '|' +
        // multi-line string
        /"""(?:\\(?:#+\((?:[^()]|\([^()]*\))*\)|[^#])|[^\\])*?"""/.source +
        ')' +
        '\\2',
      ),
      lookbehind: true,
      greedy: true,
      inside: {
        'interpolation': {
          pattern: /(\\#+\()(?:[^()]|\([^()]*\))*(?=\))/,
          lookbehind: true,
          inside: null, // see below
        },
        'interpolation-punctuation': {
          pattern: /^\)|\\#+\($/,
          alias: 'punctuation',
        },
        'string': /[\s\S]+/,
      },
    },
  ],

  'keyword-constant-like': {
    pattern: /(#colorLiteral|#column|#dsohandle|#fileID|#fileLiteral|#filePath|#file|#function|#imageLiteral|#keyPath|#line|#selector|#sourceLocation)\b/,
    alias: 'constant',
  },

  'directive': {
    // directives with conditions
    pattern: RegExp(
      /#/.source +
      '(?:' +
      (
        /(?:elseif|if)\b/.source +
        '(?:[ \t]*' +
        // This regex is a little complex. It's equivalent to this:
        //   (?:![ \t]*)?(?:\b\w+\b(?:[ \t]*<round>)?|<round>)(?:[ \t]*(?:&&|\|\|))?
        // where <round> is a general parentheses expression.
        /(?:![ \t]*)?(?:\b\w+\b(?:[ \t]*\((?:[^()]|\([^()]*\))*\))?|\((?:[^()]|\([^()]*\))*\))(?:[ \t]*(?:&&|\|\|))?/.source +
        ')+'
      ) +
      '|' +
      /(?:else|endif)\b/.source +
      ')',
    ),
    alias: 'preprocessor-statement',
    inside: {
      'directive-name': /^#\w+/,
      'boolean': /\b(?:false|true)\b/,
      'number': /\b\d+(?:\.\d+)*\b/,
      'operator': /!|&&|\|\||[<>]=?/,
      'punctuation': /[(),]/,
    },
  },

  'other-directive': {
    pattern: /(\s+|$)#\w+\b/,
    lookbehind: true,
    alias: 'preprocessor-statement',
  },

  'keyword-directive-like': {
    pattern: /(#available|#if|#elseif|#else|#endif|#error|#warning)\b/,
    alias: 'preprocessor-statement',
  },

  'type-decl': {
    pattern: /(\b(struct|class|enum|protocol|extension)\s+)\w+/,
    lookbehind: true,
    alias: 'decl',
  },

  'function-decl': {
    pattern: /(\bfunc\s+)\w+/,
    lookbehind: true,
    alias: 'decl',
  },

  'identifier-property-wrapper': {
    pattern: /@(_+)?[A-Z]\w+/,
    alias: 'identifier',
  },

  'short-argument': {
    pattern: /\$\d+\b/,
    alias: 'keyword',
  },

  'omit': {
    pattern: /\b_\b/,
    alias: 'plain',
  },

  'number': /\b(?:[\d_]+(?:\.[\de_]+)?|0x[a-f0-9_]+(?:\.[a-f0-9p_]+)?|0b[01_]+|0o[0-7_]+)\b/i,

  'keyword-decl': {
    pattern: /\b(associatedtype|class|deinit|enum|extension|fileprivate|func|import|init|inout|internal|let|open|operator|private|precedencegroup|protocol|public|rethrows|static|struct|subscript|typealias|var)\b/,
    alias: 'keyword',
  },

  'keyword-stmt': {
    pattern: /\b(break|case|catch|continue|default|defer|do|else|fallthrough|for|guard|if|in|repeat|return|throw|switch|where|while)\b/,
    alias: 'keyword',
  },

  'keyword-expr': {
    pattern: /\b(Any|as|catch|false|is|nil|rethrows|self|Self|super|throw|throws|true|try)\b/,
    alias: 'keyword',
  },

  'keyword-contextual': {
    pattern: /\b(associativity|convenience|didSet|dynamic|final|get|indirect|infix|lazy|left|mutating|none|nonmutating|optional|override|postfix|precedence|prefix|Protocol|required|right|set|some|Type|unowned|weak|willSet)\b/,
    alias: 'keyword',
  },

  'keyword-attribute': {
    pattern: /@(([a-z]\w+)|(_[a-z]w+))/,
    alias: 'keyword',
  },

  // A class name must start with an upper-case letter and be either 1 letter long or contain a lower-case letter.
  'identifier-type': {
    pattern: /\b[A-Z](?:[A-Z_\d]*[a-z]\w*)?\b/,
    alias: 'identifier',
  },

  // Operators are generic in Swift. Developers can even create new operators (e.g. +++).
  // https://docs.swift.org/swift-book/ReferenceManual/zzSummaryOfTheGrammar.html#ID481
  // This regex only supports ASCII operators.
  'operator': {
    pattern: /[-+*/%=!<>&|^~?]+|\.[.\-+*/%=!<>&|^~?]+|[;,.:]+/,
    alias: 'keyword',
  },
  'punctuation': {
    pattern: /[{}[\]()\\]/,
    alias: 'plain',
  },
};

Prism.languages.swift['string-literal'].forEach(function(rule) {
  rule.inside['interpolation'].inside = Prism.languages.swift;
});
