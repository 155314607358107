import React from 'react';
import RenderContext from './RenderContext';

export interface RichMediaMetadataProps {
  title: string,
  publishTime: number,
  tags: {
    name: string,
    counts: number,
  }[],
  author: string,
  channelName: string,
}

/**
 * Rich media metadata.
 * @param {RichMediaMetadataProps} props
 * @return {React.ReactElement}
 */
const RichMediaMetadata = (props: RichMediaMetadataProps):
  React.ReactElement => {
  return <RenderContext.Consumer>
    { (renderOptions) => {
      if (renderOptions.shouldShowMetadata) {
        return <RichMediaMetadataImpl {...props} />;
      } else {
        return <></>;
      }
    }}
  </RenderContext.Consumer>;
};

const RichMediaMetadataImpl = (props: RichMediaMetadataProps):
  React.ReactElement => {
  const {
    title,
    publishTime,
    tags,
    author,
    channelName,
  } = props;
  // The following contents are grabbed from WeChat Massive Platform source.
  return <>
    <h1 className="rich_media_title" id="activity-name">
      {title}
    </h1>
    <div id="meta_content" className="rich_media_meta_list">
      <span id="copyright_logo"
        className="wx_tap_link js_wx_tap_highlight rich_media_meta
          icon_appmsg_tag appmsg_title_tag weui-wa-hotarea"
        wah-hotarea="click">Original</span>
      <span className="rich_media_meta rich_media_meta_text">
        {author}
      </span>
      <span className="rich_media_meta rich_media_meta_nickname" id="profileBt"
        wah-hotarea="click">
        <a href="#"
          className="wx_tap_link js_wx_tap_highlight weui-wa-hotarea"
          id="js_name">
          {channelName}</a>
      </span>
      <em
        id="publish_time"
        className="rich_media_meta rich_media_meta_text">
        {formatDate(publishTime)}
      </em>
    </div>
    <div
      id="js_tags"
      className="article-tag__list"
      data-len="4"
      wah-hotarea="click">
      <div className="article-tag-card__title">收录于合集</div>
      <div className="article-tags">
        {
          tags.map( ({name, counts}, index, _2) =>
            <div key={index}
              className="article-tag__item-wrp js_tag weui-wa-hotarea
            js_wx_tap_highlight wx_tap_link"
              data-url="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzIzODEyNzQ2Mw==&amp;action=getalbum&amp;album_id=1884452548393107468#wechat_redirect" data-tag_id="" data-album_id="1884452548393107468" data-tag_source="4" role="link" tabIndex={0} aria-labelledby="js_article_tag_name__1884452548393107468 js_a11y_comma js_article_tag_num__1884452548393107468 js_article_tag_tips__1884452548393107468">
              <span aria-hidden="true" className="article-tag__item">
            #{name}
              </span>
              <span aria-hidden="true" className="article-tag__item-num">
                <span id="js_article_tag_num__1884452548393107468">
                  {counts}
                </span>
                <span
                  id="js_article_tag_tips__1884452548393107468"
                  className="weui-hidden_abs">
                个
                </span>
              </span>
            </div>,
          )
        }
      </div>
    </div>
  </>;
};

/**
 * Returns a formatted date.
 * @param {number} interval
 * @return {string}
 */
function formatDate(interval: number): string {
  const dateByInterval = new Date(interval);
  let hours = dateByInterval.getHours();
  hours = hours ? hours : 24; // the hour '0' should be '12'
  const minutes = dateByInterval.getMinutes();
  const time = `${hours}:${minutes < 10 ? '0' + minutes : `${minutes}`}`;
  const year = dateByInterval.getFullYear();
  const month = dateByInterval.getMonth();
  const date = dateByInterval.getDate();
  return `${year}-${month + 1}-${date} ${time}`;
}

export default RichMediaMetadata;
