import React from 'react';
import * as styles from './GeometricWrapper.module.scss';

interface WrapperOptions {
  hasCaption: boolean;
}

type GeometricWrapperProps = WrapperOptions & React.HTMLProps<any>

/**
 * Wrapps geometric element like `<picture>`, `<figure>`, `<image>`.
 * @param {React.ReactHTMLElement<any>} props
 * @return {React.ReactElement}
 */
const GeometricWrapper = (props: GeometricWrapperProps) => {
  const {
    hasCaption,
    ...divProps
  } = props;
  return <section className={
    hasCaption ? styles.geometryWithCaption : styles.geometry
  } {...divProps} />;
};

export default GeometricWrapper;
