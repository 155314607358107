import React from 'react';
import {reactNormalize as reactNormalize} from '../../utilities';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';

interface WrapperProps extends React.HTMLProps<any> {
  _frontmatter?: Object;
}

const Wrapper = (props: WrapperProps): React.ReactElement => {
  const fragmentSafeProps = {...props};
  const children = fragmentSafeProps.children;
  const normalizedChildren = reactNormalize(children);
  delete fragmentSafeProps.children;
  delete fragmentSafeProps._frontmatter;
  const transformedChildren = normalizedChildren.map((child, index, _) => {
    return <LayoutTraitsContext.Provider
      key={index}
      value={LayoutTraits.makeRoot(index, normalizedChildren.length)}
    >
      {child}
    </LayoutTraitsContext.Provider>;
  });
  return (
    <React.Fragment {...fragmentSafeProps}>
      {transformedChildren}
    </React.Fragment>
  );
};

export default Wrapper;
