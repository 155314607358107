import React from 'react';

/**
 * Normalize a react element or an array of react elements into an array
 * of react elements.
 * @param {T} elements
 * @return {T[]}
 */
export function reactNormalize<T=React.ReactNode>(elements: T): T[] {
  if (Array.isArray(elements)) {
    return elements;
  }
  return [elements];
};
