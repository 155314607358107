import React from 'react';
import * as styles from './TaskList.module.scss';

import TaskListItem from './TaskListItem';

import {reactNormalize} from '../../utilities';

type TaskListProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>;

const TaskList = (props: TaskListProps) => {
  const {children} = props;
  const normalizedChildren = reactNormalize(children);
  const listItemsCount = normalizedChildren.length;
  const listItems = normalizedChildren.map((child, index) => {
    if (!child || !child.hasOwnProperty('props')) {
      return undefined;
    }
    const {props} = (child as React.ReactPortal);
    return (
      <TaskListItem
        key={index}
        index={index}
        count={listItemsCount}
      >
        {props.children}
      </TaskListItem>
    );
  }).filter((_)=>_);
  return <ul className={styles.taskList}>{listItems}</ul>;
};

export default TaskList;
