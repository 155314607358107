import React from 'react';
import * as styles from './List.module.scss';

import ListItem from './ListItem';

import {reactNormalize} from '../../utilities';

type ListType = `unorderedList` | `orderedList`;

const getListStyle = (type: ListType): string => {
  switch (type) {
  case 'unorderedList':
    return styles.unorderedList;
  case 'orderedList':
    return styles.orderedList;
  }
};

interface ListProps extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement | HTMLOListElement>,
  HTMLUListElement | HTMLOListElement
> {
  type: ListType
};

const List = (props: ListProps) => {
  const {type, children} = props;
  const normalizedChildren = reactNormalize(children);
  const listClassName = getListStyle(type);
  const listItemsCount = normalizedChildren.length;
  const listItems = normalizedChildren.map((child, index) => {
    if (!child || !child.hasOwnProperty('props')) {
      return undefined;
    }
    const {
      children,
    } = (child as React.ReactPortal).props;
    return (
      <ListItem
        key={index}
        index={index}
        count={listItemsCount}
        type={`${type}Item`}
      >
        {children}
      </ListItem>
    );
  }).filter((_)=>_);
  switch (type) {
  case 'orderedList':
    return <ol className={listClassName}>{listItems}</ol>;
  case 'unorderedList':
    return <ul className={listClassName}>{listItems}</ul>;
  default:
    throw new Error(`Unexpected list type: ${type}`);
  }
};

export default List;
