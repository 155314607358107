import React from 'react';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';
import {reactNormalize} from '../../utilities';

import * as styles from './Blockquote.module.scss';

const Blockquote = (props: React.HTMLProps<any>) => {
  const children = props.children;
  const blockquoteProps = {
    ...props,
  };
  delete blockquoteProps.children;
  return <blockquote className={styles.blockquote} {...blockquoteProps}>
    {
      reactNormalize(children).map((child, index, array) => {
        const layoutTraits = LayoutTraits.makeSubElement(index, array.length);
        return (
          <LayoutTraitsContext.Provider
            key={index}
            value={layoutTraits}>
            {child}
          </LayoutTraitsContext.Provider>
        );
      })
    }
  </blockquote>;
};

export default Blockquote;
