import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Superscript.module.scss';

import Anchor from './Anchor';

import {reactNormalize, visit, Visitor} from '../../utilities';

const Superscript = (props: React.HTMLProps<any>): React.ReactElement => {
  const {id, children} = props;
  const normalizedChildren = reactNormalize(children);
  const visitor: Visitor = {
    visitElement: (element: React.ReactElement): React.ReactElement => {
      if (element.props.parentName !== 'sup') {
        return element;
      }

      return <>
        <span>[</span>
        <span>
          <Anchor href={element.props.href}>
            {element.props.children}
          </Anchor>
        </span>
        <span>]</span>
      </>;
    },
  };
  const transformedChildren = normalizedChildren.map((child, index)=>
    <React.Fragment key={index}>{visit(child, visitor)}</React.Fragment>);
  return <sup className={styles.superscript} id={id}>
    {transformedChildren}
  </sup>;
};

Superscript.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
};

export default Superscript;
