import React from 'react';
import * as ReactIs from 'react-is';
import log from './log';

/**
 * Visitor class
 */
export interface Visitor {
  preBoolean?(value: boolean): void
  visitBoolean?(value: boolean): React.ReactNode
  postBoolean?(value: boolean, result: React.ReactNode): void

  preString?(value: string): void
  visitString?(value: string): React.ReactNode
  postString?(value: string, result: React.ReactNode): void

  preNumber?(value: number): void
  visitNumber?(value: number): React.ReactNode
  postNumber?(value: number, result: React.ReactNode): void

  prePortal?(portal: React.ReactPortal): void
  visitPortal?(portal: React.ReactPortal): React.ReactNode
  postPortal?(portal: React.ReactPortal, result: React.ReactNode): void

  preElement?(element: React.ReactElement): void
  visitElement?(element: React.ReactElement): React.ReactNode
  postElement?(element: React.ReactElement, result: React.ReactNode): void

  preFragment?(fragment: React.ReactFragment): void
  visitFragment?(fragment: React.ReactFragment): React.ReactNode
  postFragment?(fragment: React.ReactFragment, result: React.ReactNode): void
}

/**
 * Default visitor.
 */
class VisitorDriver implements Visitor {
  private visitor: Visitor;

  /**
   * `ReactNode` visit dispather.
   * @param {React.ReactNode} node
   * @return {React.ReactNode}
   */
  visit(node: React.ReactNode): React.ReactNode {
    if (node === null) {
      return node;
    }

    if (node === undefined) {
      return node;
    }

    if (typeof node === 'boolean') {
      this.preBoolean(node);
      const result = this.visitBoolean(node);
      this.postBoolean(node, result);
      return result;
    }

    if (typeof node === 'string') {
      this.preString(node);
      const result = this.visitString(node);
      this.postString(node, result);
      return result;
    }

    if (typeof node === 'number') {
      this.preNumber(node);
      const result = this.visitNumber(node);
      this.postNumber(node, result);
      return result;
    }

    if (ReactIs.isPortal(node)) {
      this.prePortal(node as React.ReactPortal);
      const result = this.visitPortal(node as React.ReactPortal);
      this.postPortal(node as React.ReactPortal, result);
      return result;
    }

    if (ReactIs.isElement(node)) {
      this.preElement(node as React.ReactElement);
      const result = this.visitElement(node as React.ReactElement);
      this.postElement(node as React.ReactElement, result);
      return result;
    }

    if (ReactIs.isFragment(node)) {
      this.preFragment(node as React.ReactFragment);
      const result = this.visitFragment(node as React.ReactFragment);
      this.postFragment(node as React.ReactFragment, result);
      return result;
    }

    log(`Cannot handle type of node: ${node} while visiting it.`);

    return node;
  }

  /**
   * Pre-orderly visits a boolean member of `React.ReactNode`.
   * @param {boolean} value
   */
  preBoolean(value: boolean): void {
    if (!this.visitor.preBoolean) {
      return;
    }
    this.visitor.preBoolean(value);
  }

  /**
   * Visits a boolean member of `React.ReactNode`.
   * @param {boolean} value
   * @return {React.ReactNode}
   */
  visitBoolean(value: boolean): React.ReactNode {
    if (!this.visitor.visitBoolean) {
      return value;
    }
    return this.visitor.visitBoolean(value);
  };

  /**
   * Post-orderly visits a boolean member of `React.ReactNode`.
   * @param {boolean} value
   * @param {React.ReactNode} result result derived by `visitBoolean`
   */
  postBoolean(value: boolean, result: React.ReactNode): void {
    if (!this.visitor.postBoolean) {
      return;
    }
    this.visitor.postBoolean(value, result);
  }

  /**
   * Pre-orderly visits a boolean member of `React.ReactNode`.
   * @param {string} value
   */
  preString(value: string): void {
    if (!this.visitor.preString) {
      return;
    }
    this.visitor.preString(value);
  }

  /**
   * Visits a string member of `React.ReactNode`.
   * @param {string} value
   * @return {React.ReactNode}
   */
  visitString(value: string): React.ReactNode {
    if (!this.visitor.visitString) {
      return value;
    }
    return this.visitor.visitString(value);
  };

  /**
   * Post-orderly visits a string member of `React.ReactNode`.
   * @param {string} value
   * @param {React.ReactNode} result result derived by `visitString`
   */
  postString(value: string, result: React.ReactNode): void {
    if (!this.visitor.postString) {
      return;
    }
    this.visitor.postString(value, result);
  }

  /**
   * Pre-orderly visits a number member of `React.ReactNode`.
   * @param {number} value
   */
  preNumber(value: number): void {
    if (!this.visitor.preNumber) {
      return;
    }
    this.visitor.preNumber(value);
  }

  /**
   * Visits a number member of `React.ReactNode`.
   * @param {number} value
   * @return {React.ReactNode}
   */
  visitNumber = (value: number): React.ReactNode => {
    if (!this.visitor.visitNumber) {
      return value;
    }
    return this.visitor.visitNumber(value);
  };

  /**
   * Post-orderly visits a number member of `React.ReactNode`.
   * @param {number} value
   * @param {React.ReactNode} result result derived by `visitNumber`
   */
  postNumber(value: number, result: React.ReactNode): void {
    if (!this.visitor.postNumber) {
      return;
    }
    this.visitor.postNumber(value, result);
  }

  /**
   * Pre-orderly visits a `React.ReactPortal` member of `React.ReactNode`.
   * @param {React.ReactPortal} portal
   */
  prePortal(portal: React.ReactPortal): void {
    if (!this.visitor.prePortal) {
      return;
    }
    this.visitor.prePortal(portal);
  };

  /**
   * Visits a `React.ReactPortal` member of `React.ReactNode`.
   * @param {React.ReactPortal} portal
   * @return {React.ReactReactNode}
   */
  visitPortal(portal: React.ReactPortal): React.ReactNode {
    const newChildren = React.Children.map(
      portal.children,
      (child) => this.visit(child),
    );
    portal.children = newChildren;

    if (!this.visitor.visitPortal) {
      return portal;
    }
    return this.visitor.visitPortal(portal);
  };

  /**
   * Post-orderly visits a `React.ReactPortal` member of `React.ReactNode`.
   * @param {React.ReactPortal} portal
   * @param {React.ReactReactNode} result result derived by `visitPortal`
   */
  postPortal(portal: React.ReactPortal, result: React.ReactNode): void {
    if (!this.visitor.postPortal) {
      return;
    }
    this.visitor.postPortal(portal, result);
  };

  /**
   * Pre-orderly visits a `React.ReactElement` member of `React.ReactNode`.
   * @param {React.ReactElement} element
   */
  preElement(element: React.ReactElement): void {
    if (!this.visitor.preElement) {
      return;
    }
    this.visitor.preElement(element);
  };

  /**
   * Visits a `React.ReactElement` member of `React.ReactNode`.
   * @param {React.ReactElement} element
   * @return {React.ReactReactNode}
   */
  visitElement(element: React.ReactElement): React.ReactNode {
    const {
      props,
    } = element;
    if (props.hasOwnProperty('children')) {
      let newChildren;
      if (Array.isArray(props.children)) {
        newChildren = React.Children.map(
          props.children,
          (child) => this.visit(child),
        );
      } else {
        newChildren = this.visit(props.children);
      }

      element = {
        ...element,
        props: {
          ...props,
          children: newChildren,
        },
      };
    }

    if (!this.visitor.visitElement) {
      return element;
    }
    return this.visitor.visitElement(element);
  };

  /**
   * Post-orderly visits a `React.ReactElement` member of `React.ReactNode`.
   * @param {React.ReactElement} element
   * @param {React.ReactReactNode} result result derived by `visitElement`
   */
  postElement(element: React.ReactElement, result: React.ReactNode): void {
    if (!this.visitor.postElement) {
      return;
    }
    this.visitor.postElement(element, result);
  };

  /**
   * Pre-orderly visits a `React.ReactFragment` member of `React.ReactNode`.
   * @param {React.ReactFragment} fragment
   */
  preFragment(fragment: React.ReactFragment): void {
    if (!this.visitor.preFragment) {
      return;
    }
    this.visitor.preFragment(fragment);
  };

  /**
   * Visits a `React.ReactFragment` member of `React.ReactNode`.
   * @param {React.ReactFragment} fragment
   * @return {React.ReactReactNode}
   */
  visitFragment(fragment: React.ReactFragment): React.ReactNode {
    const newFragments = React.Children.map(
      fragment,
      (child) => this.visit(child),
    ) as React.ReactFragment;

    const normalizedNewFragments =
    Array.isArray(newFragments) && newFragments.length == 1 ?
      newFragments[0] : newFragments;

    if (!this.visitor.visitFragment) {
      return fragment;
    }
    return this.visitor.visitFragment(normalizedNewFragments);
  };

  /**
   * Post-orderly visits a `React.ReactFragment` member of `React.ReactNode`.
   * @param {React.ReactFragment} fragment
   * @param {React.ReactReactNode} result result derived by `visitFragment`
   */
  postFragment(fragment: React.ReactFragment, result: React.ReactNode): void {
    if (!this.visitor.postFragment) {
      return;
    }
    this.visitor.postFragment(fragment, result);
  };

  /**
   *
   * @param {Visitor} visitor
   */
  constructor(visitor: Visitor) {
    this.visitor = visitor;
  }
};

export const visit = (
  node: React.ReactNode,
  visitor: Visitor): React.ReactNode => {
  const driver = new VisitorDriver(visitor);
  return driver.visit(node);
};

