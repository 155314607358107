import React from 'react';
import Span from './Span';
import * as styles from './Paragraph.module.scss';
import LayoutTraitsContext from './LayoutTraitsContext';

import {reactNormalize, visit, Visitor} from '../../utilities';

type ParagraphAttributes = React.HTMLAttributes<HTMLParagraphElement>;

export interface ParagraphProps extends
  React.DetailedHTMLProps<ParagraphAttributes, HTMLParagraphElement> {
  textStyle?: string;
};

const Paragraph = (props: ParagraphProps) => {
  const {children} = props;

  const standardProps = {
    ...props,
  };
  delete standardProps.textStyle;
  delete standardProps.children;

  const normalizedChildren = reactNormalize(children);

  let unwrappableParentsCount: number = 0;

  const unwrappableMdxTypes = ['a'];
  const unwrappableClasses = ['math-inline'];

  const visitor: Visitor = {
    visitString: (value) => {
      if (unwrappableParentsCount == 0) {
        return <Span key={value}>{value}</Span>;
      }
      return value;
    },
    preElement: (element) => {
      if (element.props.hasOwnProperty('mdxType')) {
        if (unwrappableMdxTypes.includes(element.props.mdxType)) {
          unwrappableParentsCount += 1;
        }
      }
      if (element.props.hasOwnProperty('className')) {
        const className = element.props.className as string;
        const classNames = className.split(' ');
        const intersectedUnwrappableClassNames = unwrappableClasses
          .filter((x) => classNames.includes(x));
        if (intersectedUnwrappableClassNames.length > 0) {
          unwrappableParentsCount += 1;
        }
      }
    },
    postElement: (element, _) => {
      if (element.props.hasOwnProperty('mdxType')) {
        if (unwrappableMdxTypes.includes(element.props.mdxType)) {
          unwrappableParentsCount -= 1;
        }
      }
      if (element.props.hasOwnProperty('className')) {
        const className = element.props.className as string;
        const classNames = className.split(' ');
        const intersectedUnwrappableClassNames = unwrappableClasses
          .filter((x) => classNames.includes(x));
        if (intersectedUnwrappableClassNames.length > 0) {
          unwrappableParentsCount -= 1;
        }
      }
    },
  };

  return (
    <LayoutTraitsContext.Consumer>
      { (layoutTraits) =>
        <section
          className={layoutTraits.getWrapperStyle(styles)}
          {...standardProps}
        >
          <section className={styles.contents}>
            {normalizedChildren
              .map((child)=>visit(child, visitor))
              .map((child, index, _) =>
                <React.Fragment key={index}>{child}</React.Fragment>,
              )}
          </section>
        </section>
      }
    </LayoutTraitsContext.Consumer>
  );
};

Paragraph.displayName = 'Paragraph';

export default Paragraph;
