import React from 'react';

interface WrapperStyle {
  wrapper: string
  wrapperSubElementUnique?: string
  wrapperSubElementFirst?: string
  wrapperSubElementLast?: string
  wrapperRootElementUnique?: string
  wrapperRootElementFirst?: string
  wrapperRootElementLast?: string
}

export enum LayoutElementKind {
  RootElement,
  SubElement
};

/**
 * Layout traits
 */
export class LayoutTraits {
  public elementKind?: LayoutElementKind;

  public position?: number;

  public count?: number;

  /**
   * @param {LayoutElementKind | undefined} elementKind
   * @param {number | undefined} position
   * @param {number | undefined} count
   */
  constructor(
    elementKind?: LayoutElementKind,
    position?: number,
    count?: number,
  ) {
    this.elementKind = elementKind;
    this.position = position;
    this.count = count;
  }

  /**
   * Makes a default layout tratis
   * @return {LayoutTraits}
   */
  public static makeDefault(): LayoutTraits {
    return new LayoutTraits(undefined, undefined, undefined);
  }

  /**
   * Makes a root layout tratis
   * @param {number} position
   * @param {number} count
   * @return {LayoutTraits}
   */
  public static makeRoot(position: number, count: number): LayoutTraits {
    return new LayoutTraits(LayoutElementKind.RootElement, position, count);
  }

  /**
   * Makes a local layout tratis
   * @param {number} position
   * @param {number} count
   * @return {LayoutTraits}
   */
  public static makeSubElement(position: number, count: number): LayoutTraits {
    return new LayoutTraits(LayoutElementKind.SubElement, position, count);
  }

  /**
   * Returns if the info represents the first root element.
   * @return {boolean}
   */
  public isFirst(): boolean {
    if (this.position == undefined) {
      return false;
    }
    if (this.count == undefined) {
      return false;
    }
    return this.count != 0 && this.position == 0;
  }

  /**
   * Returns if the info represents the last root element.
   * @return {boolean}
   */
  public isLast(): boolean {
    if (this.position == undefined) {
      return false;
    }
    if (this.count == undefined) {
      return false;
    }
    return this.count != 0 && this.position + 1 == this.count;
  }

  /**
   * Returns if the info represents the unique root element.
   * @return {boolean}
   */
  public isUnique(): boolean {
    return this.position == 0 && this.count == 1;
  }

  /**
   * Getting a style from a `WrappperStyle`
   * @param {WrapperStyle} style
   * @return {string}
   */
  public getWrapperStyle(style: WrapperStyle): string {
    if (this.elementKind == undefined) {
      return style.wrapper;
    }

    switch (this.elementKind) {
    case LayoutElementKind.RootElement:
      if (this.isUnique()) {
        return style.wrapperRootElementUnique ??
         style.wrapperSubElementUnique ??
         style.wrapper;
      }
      if (this.isFirst()) {
        return style.wrapperRootElementFirst ?? style.wrapperSubElementFirst ??
         style.wrapper;
      }
      if (this.isLast()) {
        return style.wrapperRootElementLast ?? style.wrapperSubElementLast ??
         style.wrapper;
      }
      break;
    case LayoutElementKind.SubElement:
      if (this.isUnique()) {
        return style.wrapperSubElementUnique ?? style.wrapper;
      }
      if (this.isFirst()) {
        return style.wrapperSubElementFirst ?? style.wrapper;
      }
      if (this.isLast()) {
        return style.wrapperSubElementLast ?? style.wrapper;
      }
      break;
    }

    return style.wrapper;
  }
}

export default React.createContext(LayoutTraits.makeDefault());
