import React from 'react';
import {reactNormalize} from '../../utilities';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';

interface TableRowProps extends
  React.DetailedHTMLProps<React.TableHTMLAttributes<any>, any> {

}

const TableRow = (props: TableRowProps) =>
  <tr>
    {
      reactNormalize(props.children).map((child, index, array) => {
        const layoutTraits = LayoutTraits.makeSubElement(index, array.length);
        return <LayoutTraitsContext.Provider
          key={index}
          value={layoutTraits}>
          {child}
        </LayoutTraitsContext.Provider>;
      })
    }
  </tr>;

export default TableRow;
