import React from 'react';

export default (node: React.ReactNode) => {
  if (!node || !node.hasOwnProperty('children')) {
    return undefined;
  }

  const onlyChild = (node as React.ReactPortal).children;
  if (!onlyChild || !onlyChild.hasOwnProperty('props')) {
    return undefined;
  }

  const onlyChildProps = (onlyChild as React.ReactPortal).props;

  if (!onlyChildProps.hasOwnProperty('mdxType')) {
    return undefined;
  }

  const onlyChild2 = onlyChildProps.children;

  if (onlyChildProps.mdxType !== 'a') {
    return undefined;
  }

  if (!onlyChild2 || !onlyChild2.hasOwnProperty('props')) {
    return undefined;
  }

  const onlyChildProps2 = (onlyChild2 as React.ReactPortal).props;

  if (!onlyChildProps2.hasOwnProperty('mdxType')) {
    return undefined;
  }

  if (onlyChildProps2.mdxType != 'img') {
    return undefined;
  }

  return onlyChildProps.children;
};
