import React from 'react';
import {MDXProvider, MDXProviderComponents} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';

export type TextStyle = 'sans' | 'sans-serif';

interface MDXBodyProps extends React.HTMLProps<any> {
  components?: MDXProviderComponents;
  scope?: any;
}

const MDXBody = (props: MDXBodyProps): React.ReactElement =>
  <MDXProvider components={props.components ?? {}}>
    <MDXRenderer scope={props.scope}>
      {props.children as string}
    </MDXRenderer>
  </MDXProvider>;

export default MDXBody;
