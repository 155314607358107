import React from 'react';
import * as styles from './Strong.module.scss';

type StrongProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

const Strong = (props: StrongProps) =>
  <strong className={styles.strong} {...props} />;

Strong.displayName = 'Strong';

export default Strong;
