import React from 'react';
import * as styles from './Picture.module.scss';

const Picture = (
  props: {parentName? : string} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >,
): React.ReactElement => {
  const newProps = {...props};
  delete newProps.parentName;
  return <picture className={styles.picture} {...newProps} />;
};
Picture.displayName = 'Picture';

export default Picture;
