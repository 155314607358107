import React from 'react';
import TeX from '@matejmazur/react-katex';
import {TeXProps} from '../../utilities/pre-to-math-block';
import * as styles from './MathBlock.module.scss';

const MathBlock = (props: TeXProps) => (
  <pre className={styles.math}>
    <TeX {...props} />
  </pre>
);

MathBlock.displayName = 'MathBlock';

export default MathBlock;
