import React from 'react';
import Paragraph from './Paragraph';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';
import {reactNormalize} from '../../utilities';

import * as styles from './TaskListItem.module.scss';

interface TaskListItemProps extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOListElement>,
  HTMLOListElement
> {
  index: number;
  count: number;
};

const getListItemClass = (index: number, count: number): string => {
  if (index + 1 < count) {
    return styles.taskListItem;
  } else {
    return styles.lastTaskListItem;
  }
};

const taskListItemContent = (
  children: React.ReactNode[],
): React.ReactElement => {
  const checkbox = children[0];
  const paragraphTraits = LayoutTraits.makeSubElement(0, 1);
  return (<section className={styles.taskListItemWrapper}>
    <section className={styles.checkboxWrapper}>
      {checkbox}
    </section>
    <section className={styles.contentWrapper}>
      <LayoutTraitsContext.Provider
        value={paragraphTraits}>
        <Paragraph>
          {
            // Dropping space between checkbox and list item contents
            reactNormalize(children.slice(2))
              .map((child, index, array) => {
                const traits = LayoutTraits.makeSubElement(index, array.length);
                return (
                  <LayoutTraitsContext.Provider
                    key={index}
                    value={traits}>
                    {child}
                  </LayoutTraitsContext.Provider>
                );
              })
          }
        </Paragraph>
      </LayoutTraitsContext.Provider>
    </section>
  </section>);
};

const TaskListItem = (props: TaskListItemProps) => {
  const listItemClass = getListItemClass(props.index, props.count);
  return (
    <li className={listItemClass}>
      {taskListItemContent(reactNormalize(props.children))}
    </li>
  );
};

export default TaskListItem;
