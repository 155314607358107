import React from 'react';
import * as styles from './SegmentSeparator.module.scss';

type SeparatorProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHRElement>,
  HTMLHRElement
>

const SegmentSeparator = (props: SeparatorProps) => (
  <hr className={[styles.separator, 'body-separator'].join(' ')} {...props} />
);

SegmentSeparator.displayName = 'SegmentSeparator';

export default SegmentSeparator;
