import assert from 'assert';
import React from 'react';
import * as styles from './Heading.module.scss';
import _ from 'lodash';
import LayoutTraitsContext from './LayoutTraitsContext';

interface HeadingProps extends React.HTMLProps<any> {
  level: number,
};

const getHeadingKey = (level: number): keyof React.ReactHTML | undefined => {
  if (level === 1) {
    return undefined;
  }
  if (level === 2) {
    return `h2`;
  }
  if (level === 3) {
    return `h3`;
  }
  if (level === 4) {
    return `h4`;
  }
  if (level === 5) {
    return `h5`;
  }
  if (level === 6) {
    return `h6`;
  }
  return undefined;
};

/**
 * Represents a heading element
 */
class Heading extends React.Component<HeadingProps> {
  /**
   * Render
   * @return {React.ReactNode}
   */
  render(): React.ReactNode {
    const {level, children} = this.props;

    assert(level >= 1 && level <= 6);

    if (typeof children === 'object') {
      const [headingNumber, headingContents] = children as [string, number];
      assert(typeof headingContents === 'string');
      assert(typeof headingNumber === 'string');

      const identifier = `${_.kebabCase(headingContents)}`;

      const key = getHeadingKey(level);

      if (!key) {
        return <>{headingContents}</>;
      }

      return <LayoutTraitsContext.Consumer>
        { (traits) =>
          React.createElement(
            key,
            {className: traits.getWrapperStyle(styles)},
            (
              <>
                <section className={styles.numberWrapper}>
                  <span id={identifier} className={styles.number}>
                    {headingNumber}
                  </span>
                </section>
                <section className={styles.headingWrapper}>
                  <span id={identifier} className={styles.heading}>
                    {headingContents}
                  </span>
                </section>
              </>
            ),
          )
        }
      </LayoutTraitsContext.Consumer>;
    } else {
      return <></>;
    }
  }
}

export default Heading;
