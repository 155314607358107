// extracted by mini-css-extract-plugin
export var code = "CodeBlock-module--code--40cb8";
export var langaugeTag = "CodeBlock-module--langauge-tag--a363a";
export var language = "CodeBlock-module--language--f65b4";
export var languageSpecifiedCodeWrapper = "CodeBlock-module--language-specified-code-wrapper--0ad8d";
export var languageUnspecifiedCodeWrapper = "CodeBlock-module--language-unspecified-code-wrapper--50dcd";
export var lineContent = "CodeBlock-module--line-content--62893";
export var lineWrapper = "CodeBlock-module--line-wrapper--3518d";
export var lineWrapperLast = "CodeBlock-module--line-wrapper-last--be72d";
export var lineWrapperOnly = "CodeBlock-module--line-wrapper-only--ce428";
export var lineno = "CodeBlock-module--lineno--3582a";
export var linenoContent = "CodeBlock-module--lineno-content--27a69";
export var linenoWrapper = "CodeBlock-module--lineno-wrapper--43f12";
export var linenoWrapperLast = "CodeBlock-module--lineno-wrapper-last--e9e76";
export var linenoWrapperOnly = "CodeBlock-module--lineno-wrapper-only--11193";
export var path = "CodeBlock-module--path--305ea";
export var pathLabel = "CodeBlock-module--path-label--d7c15";
export var preFormattedCodeBlock = "CodeBlock-module--pre-formatted-code-block--ea969";