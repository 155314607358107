import React from 'react';

/**
 * Rich media content wrapper.
 * @param {React.HTMLProps<React.ReactNode>} props
 * @return {React.ReactElement}
 */
const RichMediaContent = (props: React.HTMLProps<React.ReactNode>):
 React.ReactElement =>
  <div
    className="rich_media_content fix_apple_default_style"
    id="js_content"
    style={{visibility: 'visible'}}>
    {props.children}
  </div>;

export default RichMediaContent;
