import React from 'react';
import {reactNormalize} from '../../utilities';

import * as styles from './Footnotes.module.scss';
import {visit, Visitor} from '../../utilities/visitor';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';

/**
 * Represents a foote note list
 */
class FootnoteList extends React.Component<React.ReactNode> {
  /**
   * Render
   * @return {React.React.ReactNode}
   */
  render(): React.ReactNode {
    const {children} = this.props;
    const normalizedChildren = reactNormalize(children);
    const listItems = normalizedChildren.map((child, index) => (
      <FootnoteItem key={index} {...(child as React.ReactPortal).props} />
    ));
    return <section className={styles.footnoteList}>
      {listItems}
    </section>;
  }
}

/**
 * Represents a foote note item
 */
class FootnoteItem extends React.Component<React.HTMLProps<React.ReactNode>> {
  /**
   * Render
   * @return {React.ReactNode}
   */
  render(): React.ReactNode {
    const id = this.props.id;
    if (!id) {
      return <></>;
    }
    const footnoteId = id.slice(3);

    const normalizedChildren = reactNormalize(this.props.children);

    const visitor: Visitor = {
      visitElement: (element: React.ReactElement): React.ReactNode => {
        if (element.props.className === 'footnote-backref') {
          return undefined;
        }
        return element;
      },
    };
    const transformedChildren = normalizedChildren
      .map((child)=>visit(child, visitor));

    return (
      <section id={id} className={styles.footnoteItem}>
        <section key='header' className={styles.headerWrapper}>
          <label key={'id'} className={styles.label}>
            [{footnoteId}]
          </label>
        </section>
        <section key='body' className={styles.bodyWrapper}>
          {transformedChildren}
        </section>
      </section>
    );
  }
}

const Footnotes = (props: React.HTMLProps<any>) => {
  const {children} = props;
  if (!children || children === true || !Array.isArray(children)) {
    return <></>;
  }
  const separator = children[0];
  const footnotes = children[1];
  return (
    <React.Fragment>
      {separator}
      <LayoutTraitsContext.Consumer>
        { (position) =>
          <section className={position.getWrapperStyle(styles)}>
            <LayoutTraitsContext.Provider value={LayoutTraits.makeDefault()}>
              <FootnoteList {...footnotes.props} />
            </LayoutTraitsContext.Provider>
          </section>
        }
      </LayoutTraitsContext.Consumer>;
    </React.Fragment>
  );
};

Footnotes.displayName = 'Footnotes';

export default Footnotes;
