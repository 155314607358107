import React from 'react';
import {graphql, PageProps} from 'gatsby';
import Main from '../components/Main';
import MDXBody from '../components/MDXBody';
import RichMedia from '../components/RichMedia';
import DesignSystem from '../components/system-v1';
import RichMediaMetadata from '../components/RichMediaMetadata';
import RichMediaContent from '../components/RichMediaContent';

interface PostQuery {
  post: {
    fields: {
      slug: string,
      canPublishOnWMP: boolean,
    },
    title: string,
    subtitle?: string,
    lastModifiedTime: number,
    mdx: {
      body: string
    },
    tags: {
      name: string,
      counts: number,
    }[],
  },
  site: {
    siteMetadata: {
      channelName: string,
      author: string,
    }
  },
};

/**
 * Post template component.
 * @param {PageProps<PostQuery>} props
 * @return {React.ReactElement}
 */
const Post = ({data}: PageProps<PostQuery>): React.ReactElement =>
  <Main>
    <RichMedia>
      <RichMediaMetadata
        title={data.post.title}
        publishTime={data.post.lastModifiedTime}
        tags={data.post.tags}
        author={data.site.siteMetadata.author}
        channelName={data.site.siteMetadata.channelName}/>
      <RichMediaContent>
        <MDXBody {...DesignSystem}>
          {data.post.mdx.body}
        </MDXBody>
      </RichMediaContent>
    </RichMedia>
  </Main>;

export default Post;

export const query = graphql`
query($slug: String!) {
  post(fields: {slug: {eq: $slug}}) {
    fields {
      slug
    }
    title
    subtitle
    lastModifiedTime
    mdx {
      body
    }
    tags {
      name
      counts
    }
  }
  site {
    siteMetadata {
      channelName
      author
    }
  }
}
`;
