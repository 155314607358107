import React from 'react';
import * as styles from './CodeBlock.module.scss';

import Highlight from 'prism-react-renderer';
import {defaultProps, PrismTheme, Prism} from 'prism-react-renderer';

import {CodeBlockProps} from '../../utilities/pre-to-code-block';

const GlobalThis: any = typeof global !== 'undefined' ? global : window;
GlobalThis.Prism = Prism;
require('../../utilities/prism-swift');
require('prismjs/components/prism-clike');
require('prismjs/components/prism-c');
require('prismjs/components/prism-cpp');
require('prismjs/components/prism-objectivec');
require('prismjs/components/prism-python');
require('prismjs/components/prism-shell-session');
require('prismjs/components/prism-bash');
require('prismjs/components/prism-cmake');

const linenoWrapperStyle = (lineNumber: number, lineCount: number): string => {
  if (lineNumber == 0 && lineCount == 1) {
    return styles.linenoWrapperOnly;
  }
  if (lineNumber + 1 == lineCount) {
    return styles.linenoWrapperLast;
  }
  return styles.linenoWrapper;
};

const lineWrapperStyle = (lineNumber: number, lineCount: number): string => {
  if (lineNumber == 0 && lineCount == 1) {
    return styles.lineWrapperOnly;
  }
  if (lineNumber + 1 == lineCount) {
    return styles.lineWrapperLast;
  }
  return styles.lineWrapper;
};

type Token = {
  types: string[];
  content: string;
  empty?: boolean;
};

/**
 * Merging sapces elements in a sequence of token returned by
 * Highlight.getTokenProps
 * @param {Token[]} tokens
 * @return {Token[]}
 */
function mergeWhitespaces(tokens: Token[]): Token[] {
  /*
  Type of tokens:
  type TokenOutputProps = {
    key?: React.Key;
    style?: StyleObj;
    className: string;
    children: string;
    [otherProp: string]: any;
  };
  */
  let isContiguousLeadingWhitespace = true;
  return tokens.reduce<Array<Token>>((prev, curr, _1, _2) => {
    if (prev.length == 0) {
      prev.push(curr);
      return prev;
    } else {
      const last = prev[prev.length - 1];
      // All whitespaces string's trimmed length is zero.
      if (last.content.trim().length == 0 && isContiguousLeadingWhitespace) {
        if (curr.content.trim.length != 0) {
          isContiguousLeadingWhitespace = false;
        }
        curr.content = last.content.concat(curr.content);
        prev.pop();
        prev.push(curr);
        return prev;
      } else {
        prev.push(curr);
        return prev;
      }
    }
  }, []);
}

/**
 * CodeBlock props.
 * @param {CodeBlockProps} props
 * @return {React.ReactNode}
 */
const CodeBlock = (props: CodeBlockProps) => {
  const {codeString, language, path, version} = props;

  const languageTag = getLangaugeTag(language) ?? 'default';

  const additionalLanguageTagStyles = {
    'backgroundColor': languageTagColorSets[languageTag].primary,
    'color': languageTagColorSets[languageTag].secondary,
  };

  const additionalPathLabelStyles = {
    'borderBottom': `1px solid ${languageTagColorSets[languageTag].primary}`,
  };

  const pathLabel = path ? (
    <section className={styles.pathLabel} style={additionalPathLabelStyles}>
      <span className={styles.path}>{path}</span>
    </section>
  ) : null;

  const languageName = [getLanguageName(language), version]
    .filter((_) => _)
    .join(' ');

  const languageLabel = language ? (
    <section className={styles.langaugeTag} style={additionalLanguageTagStyles}>
      <span className={styles.language}>{languageName}</span>
    </section>
  ) : null;

  return <Highlight {...defaultProps}
    theme={theme} code={codeString} language={language ?? 'clike'}>
    {({style, tokens, getLineProps, getTokenProps}) => {
      return <pre className={styles.preFormattedCodeBlock} style={style}>
        {pathLabel}
        {languageLabel}
        <section
          className={
            language ?
              styles.languageSpecifiedCodeWrapper :
              styles.languageUnspecifiedCodeWrapper
          }
        >
          <section aria-hidden={'true'} className={styles.lineno}>
            {tokens.map((_, lineNumber) => (
              <section key={lineNumber}
                className={linenoWrapperStyle(lineNumber, tokens.length)}>
                <section
                  aria-hidden={'true'}
                  className={styles.linenoContent}
                >
                  {lineNumber + 1}
                </section>
              </section>
            ))}
          </section>
          <section className={styles.code}>
            {tokens.map((line, lineNumber) => (
              <section key={lineNumber}
                className={lineWrapperStyle(lineNumber, tokens.length)}>
                <section className={styles.lineContent}>
                  <span
                    {...getLineProps({line, key: lineNumber})}>
                    {mergeWhitespaces(line).map((token, key) => (
                      <span
                        {...getTokenProps({token, key})}
                        key={key}
                      />
                    ))}
                  </span>
                </section>
              </section>
            ))}
          </section>
        </section>
      </pre>;
    }}
  </Highlight>;
};

CodeBlock.displayName = 'CodeBlock';

const getLanguageName = (language: string) => {
  if (/^objectivec$/i.test(language)) {
    return 'Objective-C';
  }
  if (/^objectivecpp$/i.test(language)) {
    return 'Objective-C++';
  }
  if (/^cpp$/i.test(language)) {
    return 'C++';
  }
  return language;
};

type SupportedLanguages = 'default' | 'swift' | 'objective-c' |
'objective-cpp' | 'c' | 'cpp' | 'python' | 'javascript' | 'typescript';

interface LanguageTagColorSet {
  primary: string,
  secondary: string,
};

interface LanguageTagColors {
  [key: string]: LanguageTagColorSet,
};

const getLangaugeTag = (language?: string):
  SupportedLanguages | undefined => {
  if (!language) {
    return undefined;
  }
  if (/^swift$/i.test(language)) {
    return 'swift';
  }
  if (/^objectivec$/i.test(language)) {
    return 'objective-c';
  }
  if (/^objectivecpp$/i.test(language)) {
    return 'objective-cpp';
  }
  if (/^c$/i.test(language)) {
    return 'c';
  }
  if (/^cpp$/i.test(language)) {
    return 'cpp';
  }
  if (/^python$/.test(language)) {
    return 'python';
  }
  if (/^js$/.test(language)) {
    return 'javascript';
  }
  if (/^ts$/.test(language)) {
    return 'typescript';
  }
  return undefined;
};

// Transcripted from Github Dark Stylish Theme
// https://github.com/StylishThemes/GitHub-Dark/blob/master/src/themes/github/github-dark.css
const theme: PrismTheme = {
  plain: {
    color: '#E1E4E8',
  },
  styles: [
    // basics
    {
      types: ['keyword'],
      style: {
        color: '#F97583',
      },
    },
    {
      types: ['decl'],
      style: {
        color: '#B392F0',
      },
    },
    {
      types: ['identifier'],
      style: {
        color: '#79B8FF',
      },
    },
    {
      types: ['comment'],
      style: {
        color: '#6A737D',
      },
    },
    {
      types: ['string'],
      style: {
        color: '#DF5A4E',
      },
    },
    {
      types: ['char'],
      style: {
        color: '#EAD87E',
      },
    },
    {
      types: ['number'],
      style: {
        color: '#7BCC72',
      },
    },
    {
      types: ['constant'],
      style: {
        color: '#DC4B1D',
      },
    },
    {
      types: ['preprocessor-statement'],
      style: {
        color: '#FD8F3F',
      },
    },
  ],
};

const languageTagColorSets: LanguageTagColors = {

  'default': {
    primary: '#51656D',
    secondary: '#51656D',
  },

  'swift': {
    primary: '#E87811',
    secondary: '#fff',
  },

  'objective-c': {
    primary: '#4F78B6',
    secondary: '#fff',
  },

  'objective-cpp': {
    primary: '#4F78B6',
    secondary: '#fff',
  },

  'cpp': {
    primary: '#965CC2',
    secondary: '#fff',
  },

  'c': {
    primary: '#875DE0',
    secondary: '#fff',
  },

  'python': {
    primary: '#7FC1E7',
    secondary: '#353535',
  },

  'javascript': {
    primary: '#42EBAE',
    secondary: '#101010',
  },

  'typescript': {
    primary: '#40D1DA',
    secondary: '#353535',
  },
};

export default CodeBlock;
