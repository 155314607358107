import React from 'react';

type Result = [React.ReactElement, boolean] | undefined;

const hasCaption = (props: React.ReactPortal): boolean => {
  const {
    children,
  } = props;
  return Array.isArray(children) && children.length > 1;
};

export default (node: React.ReactNode): Result => {
  if (!node || !node.hasOwnProperty('children')) {
    return undefined;
  }

  const onlyChild = (node as React.ReactPortal).children;

  if (!onlyChild || !onlyChild.hasOwnProperty('props')) {
    return undefined;
  }

  const props = (onlyChild as React.ReactElement).props;

  if (!props ||
    !props.hasOwnProperty('mdxType') ||
    props.mdxType !== 'figure') {
    return undefined;
  }

  return [props.children, hasCaption(props)];
};
