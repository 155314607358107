import React from 'react';

import {Link} from 'gatsby';

const Anchor = (
  props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<any>, any>,
): React.ReactElement => {
  const {href, className, children} = props;

  if (!href) {
    return children as React.ReactElement;
  }

  const newProps = {...props};
  delete newProps.href;
  delete newProps.className;

  const classNames = [className].filter((_) => _).join(' ');

  if (href.startsWith('/') && !href.startsWith('/static/')) {
    return <Link className={classNames} to={href} {...newProps}/>;
  } else {
    return <a className={classNames} href={href} {...newProps}/>;
  }
};

Anchor.displayName = 'Anchor';

export default Anchor;
