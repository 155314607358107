import React from 'react';
import * as styles from './Table.module.scss';

interface TableProps extends
  React.DetailedHTMLProps<React.TableHTMLAttributes<any>, any> {
}

const Table = (props: TableProps) => (
  <section className={styles.wrapper}>
    <table className={styles.table} {...props} />
  </section>
);

Table.displayName = 'Table';

export default Table;
