import React from 'react';
import * as styles from './Image.module.scss';

const Image = (
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<any>, any>,
): React.ReactElement => {
  const {src, alt, srcSet, sizes} = props;
  return (
    <img
      className={styles.image}
      src={src}
      alt={alt}
      srcSet={srcSet}
      sizes={sizes}
    />
  );
};

export default Image;
