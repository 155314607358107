import React from 'react';

/**
 * Render options
 */
class RenderOptions {
  public readonly shouldShowMetadata: boolean;

  public readonly colorScheme?: string;

  /**
  * Constructs a set of render options
  * @param {boolean} shouldShowMetadata
  * @param {string} colorScheme
  */
  constructor(shouldShowMetadata: boolean, colorScheme?: string) {
    this.shouldShowMetadata = shouldShowMetadata;
    this.colorScheme = colorScheme;
  }

  /**
   * Returns a set of render options.
   * @return {RenderOptions}
   */
  static make(): RenderOptions {
    const buildForDirectPaste = process.env.GATSBY_BUILD_FOR_DIRECT_PASTE ?
      true : false;
    const shouldShowMetadata = !buildForDirectPaste;
    const colorScheme = buildForDirectPaste ? 'light' : undefined;
    return new RenderOptions(
      shouldShowMetadata,
      colorScheme,
    );
  }
}

export default React.createContext(RenderOptions.make());
