import React from 'react';
import Paragraph from './Paragraph';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';
import {reactNormalize} from '../../utilities';

import * as styles from './ListItem.module.scss';

type ListItemType = `orderedListItem` | `unorderedListItem` | `taskListItem`;

interface ListItem extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> {
  type: ListItemType;
  index: number;
  count: number;
};

const getListItemClass = (
  type: ListItemType,
  index: number,
  count: number,
): string => {
  switch (type) {
  case `orderedListItem`:
    if (index + 1 < count) {
      return styles.orderedListItem;
    } else {
      return styles.lastOrderedListItem;
    }
  case `unorderedListItem`:
    if (index + 1 < count) {
      return styles.unorderedListItem;
    } else {
      return styles.lastUnorderedListItem;
    }
  case `taskListItem`:
    if (index + 1 < count) {
      return styles.taskListItem;
    } else {
      return styles.lastTaskListItem;
    }
  }
};

const ListItem = (props: ListItem) => {
  const listItemClass = getListItemClass(props.type, props.index, props.count);
  const paragraphTraits = LayoutTraits.makeSubElement(0, 1);
  return (
    <li className={listItemClass}>
      <LayoutTraitsContext.Provider value={paragraphTraits}>
        <Paragraph>
          {
            reactNormalize(props.children).map((child, index, array) => {
              const childTraits = LayoutTraits.makeSubElement(
                index,
                array.length,
              );
              return (
                <LayoutTraitsContext.Provider key={index} value={childTraits}>
                  {child}
                </LayoutTraitsContext.Provider>
              );
            })
          }
        </Paragraph>
      </LayoutTraitsContext.Provider>
    </li>
  );
};

export default ListItem;
