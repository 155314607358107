import React from 'react';
import {reactNormalize} from '../../utilities';
import LayoutTraitsContext, {LayoutTraits} from './LayoutTraitsContext';
import Paragraph from './Paragraph';

import * as styles from './TableHeader.module.scss';

interface TableHeaderProps extends
  React.DetailedHTMLProps<React.ThHTMLAttributes<any>, any> {

}

const TableHeader = (props: TableHeaderProps) => {
  return <LayoutTraitsContext.Consumer>
    { (layoutTraits) =>
      <th className={layoutTraits.getWrapperStyle(styles)} align={props.align}>
        {
          reactNormalize(props.children).map((child, index, array) => {
            const childTraits = LayoutTraits.makeSubElement(
              index,
              array.length,
            );
            return (
              <LayoutTraitsContext.Provider
                key={index}
                value={childTraits}>
                <Paragraph>
                  {child}
                </Paragraph>
              </LayoutTraitsContext.Provider>
            );
          })
        }
      </th>
    }
  </LayoutTraitsContext.Consumer>;
};

export default TableHeader;
