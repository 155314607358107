import assert from 'assert';
import React from 'react';
import {Language} from 'prism-react-renderer';

export interface CodeBlockProps {
  codeString: string;
  language: Language;
  path?: string;
  version?: string;
}

export default (node: React.ReactNode): CodeBlockProps | undefined => {
  if (!node || !node.hasOwnProperty('children')) {
    return undefined;
  }

  const child = (node as React.ReactPortal).children;
  if (!child || !child.hasOwnProperty('props')) {
    return undefined;
  }

  const props = (child as React.ReactPortal).props;

  if (!props.hasOwnProperty('mdxType')) {
    return undefined;
  }

  if (props.mdxType !== 'code') {
    return undefined;
  }

  // we have a <pre><code> situation
  const {
    children: codeString,
    className,
    ...props2
  } = props;

  assert(typeof(codeString) === 'string');

  return {
    codeString: (codeString as string).trim(),
    language: className && className.split('-')[1],
    ...props2,
  };
};
