import React, {HTMLProps} from 'react';
import * as styles from './InlineCode.module.scss';

const InlineCode = ({children}: HTMLProps<any>) => (
  <code className={styles.inlineCode}>{children}</code>
);

InlineCode.displayName = 'InlineCode';

export default InlineCode;
