/**
 * Design system for WeChat Massive Platform v1.
 */

import React from 'react';
import {MDXProviderComponents} from '@mdx-js/react';

import Wrapper from './Wrapper';
import Heading from './Heading';
import GeometricWrapper from './GeometricWrapper';
import Paragraph, {ParagraphProps} from './Paragraph';
import InlineCode from './InlineCode';
import CodeBlock from './CodeBlock';
import MathBlock from './MathBlock';
import PreFormattedBlock from './PreFormattedBlock';
import Table from './Table';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import TableData from './TableData';
import Strong from './Strong';
import SegmentSeparator from './SegmentSeparator';
import List from './List';
import Blockquote from './Blockquote';
import Checkbox from './Checkbox';
import Footnotes from './Footnotes';
import Superscript from './Superscript';
import Anchor from './Anchor';
import Image from './Image';
import Picture from './Picture';
import Figure from './Figure';
import FigureCaption from './FigureCaption';
import Span from './Span';
import InlineMath from './InlineMath';

import {
  pToImage,
  pToPicture,
  preToCodeBlock,
  pToFigure,
} from '../../utilities';
import TaskList from './TaskList';

const h1 = (props: React.HTMLProps<any>) => <Heading level={1} {...props} />;
h1.displayName = 'h1';

const h2 = (props: React.HTMLProps<any>) => <Heading level={2} {...props} />;
h2.displayName = 'h2';

const h3 = (props: React.HTMLProps<any>) => <Heading level={3} {...props} />;
h3.displayName = 'h3';

const h4 = (props: React.HTMLProps<any>) => <Heading level={4} {...props} />;
h4.displayName = 'h4';

const h5 = (props: React.HTMLProps<any>) => <Heading level={5} {...props} />;
h5.displayName = 'h5';

const h6 = (props: React.HTMLProps<any>) => <Heading level={6} {...props} />;
h6.displayName = 'h6';

const p = (props: React.ReactHTMLElement<any> | ParagraphProps) => {
  const image = pToImage(props);
  if (image) {
    return <GeometricWrapper hasCaption={false}>
      {image}
    </GeometricWrapper>;
  }
  const picture = pToPicture(props);
  if (picture) {
    return <GeometricWrapper hasCaption={false}
      {...picture as React.ReactHTMLElement<any>} />;
  }
  const figureResult = pToFigure(props);
  if (figureResult) {
    const [figure, hasCaption] = figureResult;
    return <GeometricWrapper hasCaption={hasCaption}>
      <Figure>{figure}</Figure>
    </GeometricWrapper>;
  }
  return <Paragraph {...props as ParagraphProps} />;
};
p.displayName = 'p';

type PreProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLPreElement>,
  HTMLPreElement
>;

const pre = (props: PreProps) => {
  const codeBlockProps = preToCodeBlock(props);
  // if there's a codeString and some props, we passed the test
  if (codeBlockProps) {
    return <CodeBlock {...codeBlockProps} />;
  } else {
    return <PreFormattedBlock {...props as React.ReactElement} />;
  }
};
pre.displayName = 'pre';

type OlProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOListElement>,
  HTMLOListElement
>;

const ol = (props: OlProps) => {
  return (<List type={'orderedList'} {...props} />);
};
ol.displayName = 'ol';

type UlProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>;

const ul = (props: UlProps) => {
  const {className} = props;
  if (className === 'contains-task-list') {
    return <TaskList {...props} />;
  }
  return <List type={'unorderedList'} {...props} />;
};
ul.displayName = 'ul';

interface InputsProps extends React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> {
  type: string
};

const input = (props: InputsProps): React.ReactElement => {
  if (props.type && props.type === 'checkbox') {
    return <Checkbox {...props} />;
  } else {
    return <input {...props} />;
  }
};
input.displayName = 'input';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const div = (props: DivProps) => {
  if (props.className?.includes('math-display')) {
    return <MathBlock math={props.children as string} />;
  }
  if (props.className === 'footnotes') {
    return <Footnotes {...props} />;
  }
  return <section {...props} />;
};
div.displayName = 'div';

type SpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

const span = (props: SpanProps) => {
  if (props.className?.includes('math-inline')) {
    return <InlineMath math={props.children as string} />;
  }
  return <Span {...props} />;
};
span.displayName = 'span';

export const components: MDXProviderComponents = {
  'wrapper': Wrapper,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  'blockquote': Blockquote,
  ol,
  ul,
  'table': Table,
  'th': TableHeader,
  'td': TableData,
  'tr': TableRow,
  pre,
  'strong': Strong,
  'inlineCode': InlineCode,
  'hr': SegmentSeparator,
  'a': Anchor,
  'img': Image,
  input,
  div,
  'sup': Superscript,
  'span': span,
  'picture': Picture,
  'figure': Figure,
  'figcaption': FigureCaption,
  'math': MathBlock,
  'inlinemath': InlineMath,
};

export const scope: {[key: string]: React.ComponentType<any> | undefined} = {
  InlineMath,
  MathBlock,
};

export default {
  scope,
  components,
};
