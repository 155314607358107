import React from 'react';
import TeX from '@matejmazur/react-katex';
import {TeXProps} from '../../utilities/pre-to-math-block';
import * as styles from './InlineMath.module.scss';

const InlineMath = (props: TeXProps) => (
  <section className={styles.inlineMath}>
    <TeX {...props} />
  </section>
);

InlineMath.displayName = 'InlineMath';

export default InlineMath;
